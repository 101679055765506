<template>
  <div>
    <div class="va-row">
      <div class="flex xs12 md12">
        <vuestic-widget>
          <div class="d-flex justify-content-between d">
            <el-input
              style="margin-bottom: 10px; width: 150px;"
              v-model="filters[0].value"
              placeholder="Pesquisar"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
            <button
              @click="$router.push({name:'cadastrar-empresa'})"
              class="btn btn-primary btn-micro pull-right"
            >{{'Cadastrar' | translate}}</button>
          </div>

          <div>
            <data-tables
              :data="empresas"
              :filters="filters"
              :action-col="actionCol"
              :table-props="tableProps"
              v-loading="loading"
            >
              <el-table-column
                prop="nome"
                label="NOME"
                sortable="custom"
                width="300"
              />
              <el-table-column
                prop="cnpj"
                label="CNPJ"
                sortable="custom"
              />
              <el-table-column
                prop="cidade"
                label="CIDADE"
                sortable="custom"
              />
              <el-table-column
                prop="cep"
                label="CEP"
                sortable="custom"
              />
            </data-tables>
          </div>
        </vuestic-widget>
      </div>
    </div>

    <vuestic-modal
      :show.sync="show"
      ref="staticModal"
      v-on:ok="deletarEmpresa(empresaSelecionada.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir a empresa <b>{{empresaSelecionada.nome}}</b>?</div>
    </vuestic-modal>
  </div>
</template>

<script>
import ordenar from './ordenacao';

export default {
  name: 'lista-empresas',
  data() {
    return {
      loading: false,
      show: true,
      empresas: [],
      empresaSelecionada: {},
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'DETALHES / EXCLUIR',
        props: {
          width: '220',
          align: 'center',
        },
        buttons: [
          {
            props: {
              icon: 'el-icon-edit',
              type: 'primary',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({ name: 'editar-empresa', params: { id: row.id } });
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.empresaSelecionada = row;
              this.showStaticModal();
            },
          },
        ],
      },
      filters: [
        {
          value: '',
          prop: ['nome', 'cnpj', 'cidade', 'cep'],
        },
      ],
    };
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    getEmpresa() {
      this.loading = true;
      this.$axios.get('/api/empresa.json').then((res) => {
        this.loading = false;
        this.empresas = (res.data).sort(ordenar.ordenarObjetosPorNome);
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    deletarEmpresa(id) {
      this.$axios.delete(`/api/empresa/${id}.json`).then(() => {
        this.message('success', 'Empresa excluida com sucesso');
        this.empresas.forEach((value, index) => {
          if (value.id === id) {
            this.empresas.splice(index, 1);
          }
        });
      }).catch(() => {
        this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este item.');
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
  },
};
</script>
<style >
.d {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-table .cell {
  word-break: normal !important;
}
</style>
